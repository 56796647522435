<template>
	<div>
		<page-title></page-title>
		<b-card class="mt-1" no-body>
			<b-card-header>
				<div class="d-flex align-items-center">
					<feather-icon class="mr-1" icon="FilterIcon" />
					{{ $t("Filters") }}
				</div>
			</b-card-header>
			<b-card-body>
				<b-row>
					<b-col class="mb-md-0 mb-2" cols="12" md="3">
						<p style="margin-bottom: 0.5rem">{{ $t("Ledger EquName") }}</p>
						<v-select v-model="filterValues.EQUNAME" :options="filterOptions.EQUNAME" :placeholder="$t('No filter')" class="w-100" />
					</b-col>
					<b-col class="mb-md-0 mb-2" cols="12" md="3">
						<p style="margin-bottom: 0.5rem">{{ $t("Ledger Company") }}</p>
						<v-select v-model="filterValues.COMPANY" :options="filterOptions.COMPANY" :placeholder="$t('No filter')" class="w-100" />
					</b-col>
					<b-col class="mb-md-0 mb-2" cols="12" md="3">
						<p style="margin-bottom: 0.5rem">{{ $t("Ledger Address") }}</p>
						<v-select v-model="filterValues.ADDRESS" :options="filterOptions.ADDRESS" :placeholder="$t('No filter')" class="w-100" />
					</b-col>
					<b-col class="mb-md-0 mb-2" cols="12" md="3">
						<p style="margin-bottom: 0.5rem">{{ $t("Ledger Use Status") }}</p>
						<v-select v-model="filterValues.USESTATUS" :options="filterOptions.USESTATUS" :placeholder="$t('No filter')" class="w-100" />
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
		<!-- Table Container Card -->
		<b-card class="mb-0" no-body>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col class="d-flex align-items-center justify-content-start mb-1 mb-md-0" cols="12" md="6">
						<label style="margin: 0.5rem 0">{{ $t("Show") }}</label>
						<v-select v-model="perPage" :clearable="false" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" class="per-page-selector d-inline-block mx-50" />
						<label style="margin: 0.5rem 0">{{ $t("Entries") }}</label>
					</b-col>

					<!-- Search -->
					<b-col cols="12" md="6">
						<div class="d-flex align-middle justify-content-end h-100" style="padding: 0.3rem 0">
							<download-excel :fetch="downloadData" :fields="exportExcelHeaders(tableColumns)" name="下载查询数据.xls" type="xls">
								<b-button size="sm" variant="success">
									<feather-icon class="mr-50" icon="DownloadIcon" />
									{{ $t("Download") }}
								</b-button>
							</download-excel>
						</div>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="refLedgerListTable"
				:busy="isBusy"
				:empty-text="$t('No Data')"
				:fields="tableColumns"
				:items="fetchLedgers"
				:sort-by.sync="sortBy"
				:sort-desc.sync="isSortDirDesc"
				bordered
				class="text-center position-relative"
				hover
				primary-key="ledger_id"
				responsive
				show-empty
				small
				striped
			>
				<template v-slot:table-busy>
					<div class="text-center my-2">
						<b-spinner class="align-middle mr-1"></b-spinner>
						<strong>{{ $t("Loading") }}...</strong>
					</div>
				</template>
				<!-- Column: Index -->
				<template #cell(index)="data">
					<div class="text-nowrap">
						<span class="align-text-top text-capitalize">
							{{ data.index + 1 }}
						</span>
					</div>
				</template>
				<!-- Column: Index -->
				<template #cell(actions)="data">
					<b-button v-b-modal:ledger-detail-modal class="btn-icon" size="sm" variant="warning" @click="ledgerDetail = data.item">
						<feather-icon icon="MoreHorizontalIcon" />
					</b-button>
				</template>
			</b-table>
			<div class="mx-2 mb-2">
				<b-row>
					<b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
						<span class="text-muted">
							{{
								`
                            ${$t("Show")}
                            ${dataMeta.from}
                            ${$t("to")}
                            ${dataMeta.to}
                            ${$t("of")}
                            ${total}
                            ${$t("Entries")}
                            `
							}}
						</span>
					</b-col>
					<!-- Pagination -->
					<b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">
						<b-pagination v-model="currentPage" :per-page="perPage" :total-rows="total" hide-goto-end-buttons size="sm"></b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<b-modal id="ledger-detail-modal" hide-footer size="lg" title="设备详情">
			<b-card class="p-1" no-body>
				<b-row>
					<b-col cols="4">
						<b-card class="w-100 h-100 position-relative" no-body>
							<b-img :src="String(ledgerDetail.image || '')" alt="" class="w-100 h-100" />
							<b-button class="position-absolute" size="sm" style="padding: 0.2rem; right: 0" variant="outline-warning" @click="$refs.refInputEl.$el.click()">
								<b-form-file ref="refInputEl" :hidden="true" accept=".jpg, .png, .gif" plain @input="uploadImage" />
								<feather-icon icon="UploadIcon" />
							</b-button>
						</b-card>
					</b-col>
					<b-col cols="8">
						<b-row>
							<b-col cols="12">
								<h4>设备信息</h4>
								<h5>设备标识：<span v-text="ledgerDetail.EQUSIGN || 'N/A'"></span></h5>
								<h5>设备说明：<span v-text="ledgerDetail.EQUMEMO || 'N/A'"> </span></h5>
							</b-col>
						</b-row>
						<b-row class="my-1">
							<b-col cols="5">
								<h4>采购信息</h4>
								<h5>供应商：<span v-text="ledgerDetail.EQUSUPPLIER || 'N/A'"></span></h5>
								<h5>购买时间：<span v-text="ledgerDetail.BUYDT || 'N/A'"></span></h5>
								<h5>购置方式：<span v-text="ledgerDetail.BUYTYPE || 'N/A'"></span></h5>
								<h5>资产原值：<span v-text="ledgerDetail.EQUORIGVALUE || 'N/A'"> </span></h5>
								<h5>设备净值：<span v-text="ledgerDetail.EQUITY || 'N/A'"> </span></h5>
							</b-col>
							<b-col cols="7">
								<h4>检修信息</h4>
								<h5>检验周期：<span v-text="ledgerDetail.CHECKCYCLE || 'N/A'"></span></h5>
								<h5>上次检验：<span v-text="ledgerDetail.LASTCHECK || 'N/A'"></span></h5>
								<h5>下次检验：<span v-text="ledgerDetail.NEXTCHECK || 'N/A'"></span></h5>
								<h5>上次维修：<span v-text="ledgerDetail.LASTMAINTAIN || 'N/A'"></span></h5>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-card>
		</b-modal>
	</div>
</template>

<script>
	import { momentJs } from "@/libs/utils/moment";
	import vSelect from "vue-select";
	import JsonExcel from "vue-json-excel";
	import Ripple from "vue-ripple-directive";
	import { computed, onMounted, reactive, toRefs, watch } from "@vue/composition-api";
	import { showToast } from "@/libs/utils/showToast";
	import { getLedgers, getLedgersOptions, updateLedger } from "@/api/front";
	import PageTitle from "@/views/layout/PageTitle";
	import { routerParams } from "@/libs/utils/routerParams";
	import { t } from "@core/libs/i18n/utils";
	import { cos_upload } from "@/libs/utils/uploadFiles";

	export default {
		directives: {
			Ripple,
		},
		components: {
			PageTitle,
			vSelect,
			downloadExcel: JsonExcel,
		},
		setup() {
			const event = reactive({
				project_id: computed(() => {
					return routerParams("project_id");
				}),
				filterOptions: {},
				filterValues: {
					EQUNAME: null,
					COMPANY: null,
					ADDRESS: null,
					USESTATUS: null,
				},
				isEditLedgerSidebarActive: false,
				refLedgerListTable: null,
				perPage: 10,
				searchQuery: "",
				tableColumns: [
					{
						label: "#",
						key: "index",
					},
					{
						label: "设备ID",
						key: "EQUSID",
					},
					{
						label: "设备名称",
						key: "EQUNAME",
						sortable: true,
					},
					{
						label: "规格型号",
						key: "EQUMODLE",
						sortable: true,
					},
					{
						label: "生产厂家",
						key: "COMPANY",
					},
					{
						label: "负责人",
						key: "EQUMANAGER",
					},
					{
						label: "所在部门",
						key: "DEPARTMENT",
					},
					{
						label: "安装地点",
						key: "ADDRESS",
						sortable: true,
					},
					{
						label: "使用状态",
						key: "USESTATUS",
						sortable: true,
					},
					{
						label: "使用人员",
						key: "OPTPERSON",
						sortable: true,
					},
					{
						label: "操作",
						key: "actions",
					},
				],
				total: 0,
				currentPage: 1,
				perPageOptions: [10, 25, 50, 100],
				sortBy: "",
				isSortDirDesc: false,
				dataMeta: computed(() => {
					const localItemsCount = event.refLedgerListTable ? event.refLedgerListTable.localItems.length : 0;
					return {
						from: event.perPage * (event.currentPage - 1) + (localItemsCount ? 1 : 0),
						to: event.perPage * (event.currentPage - 1) + localItemsCount,
						of: event.total,
					};
				}),
				ledgerDetail: {},
				refInputEl: null,
				isBusy: false,
			});

			watch([() => event.currentPage, () => event.perPage, () => event.isEditLedgerSidebarActive, () => event.searchQuery], () => {
				console.log("change");
				if (!event.isEditLedgerSidebarActive) {
					refetchData();
				}
			});

			watch(
				event.filterValues,
				() => {
					console.log("change", event.filterValues);
					refetchData();
				},
				"deep"
			);

			const refetchData = () => {
				event.refLedgerListTable.refresh();
			};

			const fetchLedgers = (ctx, callback) => {
				event.isBusy = true;
				const filters = getFilters();
				const params = {
					project_id: event.project_id,
					pagesize: event.perPage,
					page: event.currentPage,
					sortBy: event.sortBy,
					sortDesc: event.isSortDirDesc ? 1 : 0,
					...filters,
				};
				getList(params)
					.then((response) => {
						const { list, total } = response.data;
						callback(list);
						event.total = total;
						if (!total) {
							showToast(null, "提示", "未查询到数据");
						}
						event.isBusy = false;
					})
					.catch((error) => {
						event.isBusy = false;
						showToast(error);
					});
			};
			/**
			 * 获取列表数据
			 */
			const getList = async (params) => {
				return getLedgers({
					project_id: event.project_id,
					...params,
				});
			};
			/**
			 * 获取参数
			 */
			const getOptions = () => {
				getLedgersOptions({
					project_id: event.project_id,
				})
					.then((response) => {
						event.filterOptions = response.data;
					})
					.catch((error) => {
						showToast(error);
					});
			};
			/**
			 * 处理导出数据的头部
			 * @param fields
			 */
			const exportExcelHeaders = (fields) => {
				let headers = {};
				fields.map((item) => {
					if (item.key !== "index" && item.key !== "actions") {
						let header = t(item.label) + "(" + item.key + ")";
						headers[header] = item.key;
					}
				});
				return headers;
			};
			/**
			 * 获取筛选条件
			 */
			const getFilters = () => {
				let filters = {};
				for (const Key in event.filterValues) {
					let Value = event.filterValues[Key];
					if (!!Value) {
						filters[Key] = Value;
						if (Key === "time") {
							Value = Value.replaceAll(" 至 ", ",").replaceAll(" to ", ",");
							const timeArr = Value.split(",");
							const start = momentJs(timeArr[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss");
							const end = momentJs(timeArr[1] || timeArr[0])
								.endOf("day")
								.format("YYYY-MM-DD HH:mm:ss");
							filters[Key] = "(" + start + "," + end + ")";
						}
					}
				}
				return filters;
			};
			/**
			 * 下载数据
			 * @returns {Promise<*>}
			 */
			const downloadData = async () => {
				const filters = getFilters();
				const params = {
					sortBy: event.sortBy,
					sortDesc: event.isSortDirDesc ? 1 : 0,
					...filters,
				};
				const res = await getList(params);
				if (!res.data.total) {
					showToast(null, "提示", "暂无数据可以下载");
				}
				return res.data.list;
			};
			const uploadImage = () => {
				const file = event.refInputEl.files[0];
				cos_upload(
					file,
					(progress) => {
						console.log("progress", progress);
					},
					(res) => {
						console.log("success");
						console.log("res", res);
						event.ledgerDetail.image = "https://" + res.Location;
						updateLedger({
							project_id: event.project_id,
							equsid: event.ledgerDetail.EQUSID,
							image: res.filepath,
						})
							.then()
							.catch((error) => {
								showToast(error);
							});
					},
					"uploads/ledgers"
				);
			};
			onMounted(() => {
				getOptions();
			});
			return {
				...toRefs(event),
				fetchLedgers,
				exportExcelHeaders,
				downloadData,
				uploadImage,
			};
		},
	};
</script>

<style lang="scss" scoped>
	/* .per-page-selector {
  width: 90px;
} */
</style>

<style lang="scss">
	@import "../../../../@core/scss/vue/libs/vue-flatpicker";
	@import "../../../../@core/scss/vue/libs/vue-select";
</style>

import useJwt from "@/libs/auth/jwt/useJwt";

const axios = useJwt.axiosIns;

/**
 * 微服务列表相关接口
 */
export const getAgents = (params) => axios.get("/api/agent/list", { params });
export const getFaultAgents = (params) => axios.get("/api/diagnosis/fault/list", { params });
export const getFaultAgentsFold = (params) => axios.get("/api/diagnosis/fault/fold", { params });
export const getFaultAgentsLeftLevel = (params) => axios.get("/api/diagnosis/fault/left_level", { params });
export const getAgentsOptions = (params) => axios.get("/api/agent/options", { params });
/**
 * 警报列表相关接口
 */
export const getAlarms = (params) => axios.get("/api/alarm/list", { params });
export const getAlarmsOptions = (params) => axios.get("/api/alarm/options", { params });
/**
 * 巡检列表相关接口
 */
export const getInspections = (params) => axios.get("/api/inspection/list", { params });
export const getInspectionsOptions = (params) => axios.get("/api/inspection/options", { params });
export const downloadInspectionsExcel = (params) => axios.get("/api/inspection/download_excel", { params });
/**
 * 设备台账列表相关接口
 */
export const getLedgers = (params) => axios.get("/api/ledger/list", { params });
export const getLedgersOptions = (params) => axios.get("/api/ledger/options", { params });
export const updateLedger = (data) => axios.post("/api/ledger/update", data);

/**
 * 报修列表相关接口
 */
export const getRepairs = (params) => axios.get("/api/repair/list", { params });
export const updateRepairsPrintCount = (data) => axios.post("/api/repair/print_count", data);

export const getRepairsOptions = (params) => axios.get("/api/repair/options", { params });
